<template>
	<div v-for="dt in alertBanners" :key="dt.alertKey" uk-alert :class="alertClass(dt.alertLevel)">
		<span>{{ dt.alertMessage }}</span>
		<a href class="uk-alert-close" uk-close></a>
	</div>
</template>

<script>
import Filters from "../../Mixins/Filters.vue";
export default {
	name: "AlertBanner",

	props: {},
	components:{},
	mixins: [Filters],
	data() {
		return {
			defaultAlert:{
				alertLevel: "danger",
				alertMessage: "No message sent."
			},
			alertBanners: []
		}
	},
	mounted(){
		
	},
	methods: {
		showBanner(message = "Unknown error.", level = "danger"){
			let alertData = {
				alertMessage: message,
				alertLevel: level,
				alertKey: this.generateString()
			};
			this.alertBanners.push(alertData);
			this.$forceUpdate();
		},
		alertClass(alertLevel){
			switch(alertLevel){
				case "primary":
					return "uk-alert-primary";
				case "success":
					return "uk-alert-success";
				case "warning":
					return "uk-alert-warning";
				case "danger":
				default:
					return "uk-alert-danger";
			}
		}
	},
	computed:{
		
	},
	watch:{}
}
</script>

<style scoped>

</style>